<template>
    <div class="version">
        <p v-if="$const.COPYRIGHT_URL">
            <a :href="$const.COPYRIGHT_URL" target="_blank" v-html="`© ${$const.IN_SITE_COPYRIGHT}`"></a>
        </p>
        <p v-else class="mb-0" v-html="`© ${$const.IN_SITE_COPYRIGHT}`"></p>
        <div class="platon-versions-stack">
            <div class="line"></div>
            <div class="platon-versions">
                <span class="platon-version">{{ $ll("platon.appVersion", [$appVersion], "version {0}") }}</span>
                <span class="platon-version">{{ $projectInfo.version }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeftMenuFooter"
}
</script>

<style>
.version p {
    font-weight: 500;
}
.platon-version {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.8;
}
.platon-versions-stack {
    display: flex;
    align-items: center;
    justify-content: center;
}
.platon-versions-stack .line {
    width: 24px;
    height: 3px;
    background-color: #08080870;
    transform: rotate(90deg);
}

.platon-versions {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 17px;
}
</style>
