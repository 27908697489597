<template>
    <component
        :is="`${localItem.type.toLowerCase().replace(/_/g, '-')}-control`"
        v-if="visible && shouldDisplay"
        v-bind="extraOptions"
        :value="$form.dataHolder[item.dataField]"
        ref="element"
        :style="elementStyle"
        :class="localItem.cssClass"
        @input="updateLocal"
        :placeholder="localItem.placeholder"
        :data-name="localItem.dataField"
        :label="localItem.label"
        :size="localItem.width"
        :hint="localItem.hint"
        :item="localItem"
        :element-type="localElementType"
        :element-id="localItem.id"
        :key="`e-${localItem.id}`"
        :required="localItem.isRequired"
        :disabled="localItem.isDisabled"
        :readonly="localItem.isReadOnly"
    />
</template>

<script>
import TextControl from "@Platon/components/form/controls/TextControl.vue"
import InputControl from "@Platon/components/form/controls/TextControl.vue"
import PasswordControl from "@Platon/components/form/controls/PasswordControl.vue"
import SelectControl from "@Platon/components/form/controls/SelectControl.vue"
import CheckboxControl from "@Platon/components/form/controls/CheckboxControl.vue"
import DateControl from "@Platon/components/form/controls/DateControl.vue"
import DatetimeControl from "@Platon/components/form/controls/DatetimeControl.vue"
import TimeControl from "@Platon/components/form/controls/TimeControl.vue"
import TextareaControl from "@Platon/components/form/controls/TextareaControl.vue"
import NumberControl from "@Platon/components/form/controls/NumberControl.vue"
import FileControl from "@Platon/components/form/controls/UploadControl.vue"
import HtmlControl from "@Platon/components/form/controls/HtmlControl.vue"
import ButtonControl from "@Platon/components/form/controls/ButtonControl.vue"
import FormDataMixin from "@Platon/components/form/FormDataMixin"
import FocusableFormElementMixin from "@Platon/components/form/FocusableFormElementMixin"
import KeyValueControl from "@Platon/components/form/controls/KeyValueControl.vue"
import { IS_DEV } from "@Platon/const"
import TabContainerControl from "@Platon/components/form/controls/TabContainerControl.vue"
import TabControl from "@Platon/components/form/controls/TabControl.vue"
import RawJsonControl from "@Platon/components/form/controls/RawJsonControl.vue"
import { wrapWithParamsAndArgs } from "@Platon/core/condition"
import { PermissionsScope } from "@Platon/core/runScope"

export default {
    name: "PlatonFormElement",

    mixins: [FormDataMixin, FocusableFormElementMixin],

    components: {
        InputControl,
        CodeControl: () => import("@Platon/components/form/controls/CodeControl.vue"),
        MonacoEditorControl: () => import("@Platon/components/form/controls/MonacoEditorControl.vue"),
        VueEditorControl: () => import("@Platon/components/form/controls/VueEditorControl/VueEditor.vue"),
        GeoControl: () => import("@Platon/components/form/controls/MapControl.vue"),
        GeoPolygonControl: () => import("@Platon/components/form/controls/MapPolygonControl.vue"),
        HtmlControl,
        DateControl,
        DatetimeControl,
        CheckboxControl,
        SelectControl,
        MultiselectControl: SelectControl,
        TextControl,
        TextareaControl,
        PasswordControl,
        NumberControl,
        TimeControl,
        FileControl,
        ImageControl: () => import("@Platon/components/form/controls/ImageControl.vue"),
        ButtonControl,
        TabContainerControl,
        TabControl,
        SimpleKeyValueControl: KeyValueControl,
        FieldsetControl: () => import("@Platon/components/form/controls/PlatonFieldset.vue"),
        MarkdownEditorControl: () => import("@Platon/components/form/controls/MarkdownEditorControl.vue"),
        // 'wysiwyg-control': () => import("@Platon/components/form/controls/WYSIWYGControl.vue"),
        "wysiwyg-control": () => import("@Platon/components/form/controls/TinyMCEControl.vue"),
        RawJsonControl
    },

    props: {
        /** @type PlatonFormElement */
        item: {},
        elementType: {
            default: "form_elements"
        }
    },

    data() {
        return {
            localItem: this.item,
            localElementType: this.elementType,
            visible: true,

            displayConditionFn: null,
            displayConditionArgs: {}
        }
    },

    inject: {
        form: {
            default: undefined
        }
    },

    watch: {
        shouldDisplay(val, old) {
            if (val) {
                this.$form.hiddenElements.delete(this.item.dataField)
            } else {
                this.$form.hiddenElements.set(this.item.dataField, this)
            }
        }
    },

    beforeMount() {
        this.$form.$on("remoteErrors", this.addFlashError)

        this.displayConditionArgs = this.getDisplayFnScope()
        this.displayConditionFn = this.getDisplayFn()
    },

    mounted() {
        if (this.item.dataField) {
            this.$form.elements.set(this.item.dataField, this)
        }

        if (this.item.id) {
            this.$form.elements.set(this.item.id, this)
        }
    },

    beforeDestroy() {
        this.$form.$off("remoteErrors", this.addFlashError)

        if (this.item.dataField) {
            this.$form.elements.delete(this.item.dataField)
        }

        if (this.item.id) {
            this.$form.elements.delete(this.item.id)
        }
    },

    updated() {
        if (IS_DEV) console.table("Updated PFE", this.item.dataField)
    },

    methods: {
        addFlashError(error) {
            if (this.$refs.element && this.item.dataField && error[this.item.dataField])
                this.$refs.element.flashErrors = [error[this.item.dataField]]
        },

        focus() {
            if (this.$refs.element && typeof this.$refs.element.focusField === "function")
                this.$refs.element.focusField()
        },

        updateLocal($v) {
            this.notifyDataChange(this.localItem, $v)

            if (this.$refs.element && this.$refs.element.flashErrors && this.$refs.element.flashErrors.length > 0) {
                this.$refs.element.flashErrors = []
            }
        },

        getDisplayFn() {
            let condition = this.displayConditionExpr

            if (!condition) {
                return null
            }

            return wrapWithParamsAndArgs(condition, Object.keys(this.displayConditionArgs))
        },

        getDisplayFnScope() {
            return { ...PermissionsScope(), form: this.form }
        }
    },

    computed: {
        displayConditionExpr() {
            return this.localItem.displayCondition
        },

        displayConditionParams() {
            return this.formData
        },

        shouldDisplay() {
            if (this.displayConditionFn !== null) {
                try {
                    const result = this.displayConditionFn.call(
                        this.displayConditionParams,
                        ...Object.values(this.displayConditionArgs)
                    )

                    return !!result
                } catch (e) {
                    console.warn("Can not calc display condition", e)
                }
            }

            return true
        },

        elementStyle() {
            if (this.item.type === "fieldset") {
                return undefined
            }

            return this.item.cssStyle
        },

        extraOptions() {
            let attrs = {}

            if (this.localItem.type === "html") {
                attrs.syntax = "html"
            }

            attrs = {
                ...attrs,
                ...this.$attrs
            }

            return attrs
        }
    }
}
</script>
